<template>
  <div class="activation-page" ref="pageEle">
    <div class="head">
      <div class="bar"
        ref="barEle"
        v-for="(item, index) in activationList"
        :key="index"
        :class="{ active: curIndex === index }"
        @click="clickAction(index)"
        >
        <span>{{item.name}}</span>
      </div>
      <div class="sideline"
        ref="lineEle"
        :style="{ left: left + 'px' }"></div>
    </div>
    <div class="move-page">
      <div class="swiper-container">
        <div class="swiper-wrapper" ref="movePage">
          <div class="activation-row swiper-slide">
            <div class="ac-card" v-for="(item, index) in noPrizeList" :key="index">
              <div class="vip-bg">
                <div class="image">
                  <img
                src="//download-cos.yofish.com/yofish-gongjushiyebu/20221122110522752-vip-fill.png"
                alt="">
                </div>
              </div>
              <div class="content">
                <p class="title">永久会员激活码</p>
                <p class="time">获得时间：{{item.time}}</p>
              </div>
              <div class="button" @click="openModal(item)">去激活</div>
            </div>
            <div class="empty" v-if="!noPrizeList.length">
              <div class="empty-image">
                <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221123151244968-vip-empty.png" alt="">
              </div>
              <p class="empty-desc">暂无激活码～</p>
            </div>
          </div>
          <div class="activation-row swiper-slide">
            <div class="ac-card disabled" v-for="(item, index) in prizeList" :key="index">
              <div class="vip-bg">
                <div class="image">
                  <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221124203114187-default-vip-fill.png"
                    alt="">
                </div>
              </div>
              <div class="content">
                <p class="title">永久会员激活码</p>
                <p class="time">获得时间：{{item.time}}</p>
              </div>
              <div class="button" @click="openModal(item)">去查看</div>
            </div>
            <div class="empty" v-if="!prizeList.length">
              <div class="empty-image">
                <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221123151244968-vip-empty.png" alt="">
              </div>
              <p class="empty-desc">暂无激活码～</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-----激活码弹窗------>
    <y-dialog :visible.sync="codeVisible" :appendToBody="false"
      class="activation-dialog"
      :class="{ 'activation-details-dialog': isDetails }">
      <div class="close-image" slot="header" @click="codeVisible = false"
        v-if="isDetails">
        <img
        src="//download-cos.yofish.com/yofish-gongjushiyebu/20221024140036315-close-btn.png" alt="">
      </div>
      <div class="content">
        <p class="title">会员激活码</p>
        <div class="userinfo">
          <span>用户手机号</span>
          <div class="input">
            <input type="text" v-model="phoneNo" :disabled="isDetails" maxlength="11">
          </div>
        </div>
        <p class="desc" v-if="!isDetails">注：输入用户手机号，即可帮该用户激活会员。会员到账可能延迟，如延迟，请重新打开app</p>
        <p class="desc time" v-if="isDetails">激活时间：{{activationTime}}</p>
      </div>
      <div class="btn-group" v-if="!isDetails">
        <div class="btn cancel-btn" @click="codeVisible = false">取消</div>
        <div class="btn sure-btn" @click="okAction">确认激活</div>
      </div>
    </y-dialog>
    <y-loading v-if="isLoading" :appendToBody="false" />
  </div>
</template>
<script>
import {
  getMyBlindboxPrizeApi,
  exchangePrizeBlindboxApi,
} from '@/api/base1';
import {
  isArray, getNewDayYMDHMS, changeParam,
  debounce, checkPhone, getNewsYMDHMS,
  isChinese,
} from '@/utils';
import YDialog from '@/components/dialog';
import YLoading from '@/components/loading';
import { getAppStatus } from '@/utils/app';
// import Swiper from 'swiper';
// import 'swiper/dist/css/swiper.css';

export default {
  name: 'PackageActivation',
  components: { YDialog, YLoading },
  data() {
    return {
      activationList: [
        { name: '未激活', value: 0 },
        { name: '已激活', value: 1 },
      ],
      curIndex: 0,
      left: 0,
      codeVisible: false,
      phoneNo: '', // 手机号
      isDetails: false,
      noPrizeList: [], // 未激活
      prizeList: [], // 已激活
      activationTime: '',
      isLoading: false,
      isModel: false,
    };
  },
  computed: {
    activityId() {
      return 5;
    },
    cuserId() {
      let { cuserId } = this.$route.query;
      if (cuserId) {
        return cuserId;
      }
      return getAppStatus().cuserId;
      // return '1267301d-36d4-4503-aa52-260f5c78c328';
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
    },
  },
  watch: {
    codeVisible(val) {
      this.isModel = val;
      if (!val) {
        this.phoneNo = '';
      }
    },
  },
  mounted() {
    document.title = '会员激活码';
    this.getMyPrizeList();
    const that = this;
    const { lineEle, pageEle } = this.$refs;
    const pageWidth = pageEle.clientWidth;
    const barEle = document.querySelector('.bar');
    const barW = barEle.clientWidth;
    const lineW = lineEle.clientWidth;
    this.Swiper = new window.Swiper('.swiper-container', {
      loop: false,
      on: {
        slideChangeTransitionStart() {
          let curIndex = this.activeIndex;
          that.curIndex = curIndex;
          if (curIndex === 1) {
            that.left = (pageWidth / 2) + (barW / 2) - (lineW / 2);
            console.log(that.left);
          }
          if (curIndex === 0) {
            that.left = (barW / 2) - (lineW / 2);
          }
        },
        slideChangeTransitionEnd() {},
      },
    });
    this.$nextTick(() => {
      this.elementAction();
    });
  },
  methods: {
    async getMyPrizeList() {
      let res;
      const { cuserId, activityId } = this;
      this.isLoading = true;
      try {
        res = await getMyBlindboxPrizeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        return this.$toast(res?.desc || '网络异常');
      }
      this.isLoading = false;
      if (res?.code === 1) {
        let prizeList = isArray(res?.results) ? res.results : [];
        prizeList = prizeList.map((item) => ({ ...item, time: getNewDayYMDHMS(item.createTime) }));
        this.noPrizeList = prizeList.filter((item) => item.exchangeStatus === 0);
        this.prizeList = prizeList.filter((item) => item.exchangeStatus === 1);
      }
    },
    elementAction(index) {
      const bar = document.querySelector('.bar.active');
      const line = document.querySelector('.sideline');
      const { pageEle, movePage } = this.$refs;
      let pageW = pageEle.clientWidth;
      let { offsetLeft } = bar;
      let barW = bar.clientWidth;
      let lineW = line.clientWidth;
      let left = 0;
      this.left = (barW / 2 - lineW / 2) + offsetLeft;
      const { curIndex } = this;
      this.Swiper.slideTo(curIndex, 500, false);
    },
    clickAction(index) {
      this.curIndex = index;
      this.$nextTick(() => {
        this.elementAction(index);
      });
    },
    openModal(data) {
      console.log('open');
      let { exchangeStatus, id, phoneNo } = data;
      this.activationData = data;
      this.winningPrizeId = id;
      this.isDetails = !!exchangeStatus;
      this.codeVisible = true;
      if (exchangeStatus) {
        this.phoneNo = phoneNo;
        this.activationTime = getNewsYMDHMS(data.updateTime);
      }
    },
    okAction: debounce(async function () {
      let res;
      const {
        cuserId, winningPrizeId, activityId,
        phoneNo,
      } = this;
      // if (phoneNo === '') return this.$toast('请输入正确的手机号！');
      if (!checkPhone(this.phoneNo)) return this.$toast('请输入正确的手机号！');
      if (this.submiting) return false;
      this.submiting = true;
      let data = {
        cuserId, winningPrizeId, activityId, phoneNo,
      };
      data = changeParam(data);
      let appInfo;
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        appInfo = window.ios.jzAppInfo();
      } else if (/android/i.test(navigator.userAgent)) {
        appInfo = window.android.jzAppInfo();
        this.type = 2;
      } else {
        appInfo = {
          name: 'com.jz.youyu',
          channel: '官网',
          version: '5.1.0',
          osType: 1,
        };
        appInfo = JSON.stringify(appInfo);
      }
      appInfo = JSON.parse(appInfo);
      let { channel, name } = appInfo;
      name = isChinese(name) ? encodeURIComponent(name) : name;
      channel = isChinese(channel) ? encodeURIComponent(channel) : channel;
      const header = {
        name,
        osType: appInfo.osType,
        channel,
        releaseVersion: appInfo.version,
      };
      try {
        res = await exchangePrizeBlindboxApi({}, data, header);
      } catch (e) {
        console.log(e);
        this.submiting = false;
        if (e.code === -3000) {
          return this.$toast('该手机号未注册！');
        }
        if (e.code === -2224) {
          return this.$toast('该用户已是永久会员，无需激活！');
        }
        return this.$toast({
          content: e?.desc || '激活失败',
        });
      }
      if (res?.code === 1) {
        this.codeVisible = false;
        this.submiting = false;
        this.$toast({
          content: '激活成功',
        });
        this.getMyPrizeList();
      } else {
        this.submiting = false;
        this.$toast({
          content: res?.desc || '激活失败',
        });
      }
    }, 600, true),
  },
};
</script>
<style lang="scss" scoped>
@import './styles/activation.scss';
</style>
